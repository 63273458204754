<div class="h-100 w-100 overflow-hidden d-flex flex-column">
  <div class="header">
    <app-default-header *ngIf="headerStateService.isDefaultHeaderDisplayed | async"></app-default-header>
    <app-detail-header *ngIf="headerStateService.isDetailHeaderDisplayed | async"></app-detail-header>
  </div>
  <div class="flex-grow-1 overflow-y-auto d-flex flex-column">
    <div class="container py-3">
      <router-outlet></router-outlet>
    </div>
    <div class="flex-grow-1">

    </div>
    <app-sponsors *ngIf="sponsorsStateService.isSponsorFooterDisplayed | async"></app-sponsors>
  </div>
  <div class="footer">
    <app-navbar></app-navbar>
  </div>
</div>