import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { HeaderStateService } from './main/headers/services/header-state.service';
import { SponsorsStateService } from './shared/components/sponsors/services/sponsors-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'web-staff-app';

  constructor(
    protected headerStateService: HeaderStateService,
    protected sponsorsStateService: SponsorsStateService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {

  }
}
