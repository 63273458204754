import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserAuthorizationService } from './user-authorization.service';

@Injectable({
    providedIn: 'root'
  })
export class AuthorizationInterceptor implements HttpInterceptor {

    constructor(private authService: UserAuthorizationService) {}
    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const modifiedRequest = req.clone({setHeaders: {'Authorization': 'Bearer ' + this.authService.getAccessToken()}});
        return next.handle(modifiedRequest);
    }
}
