import { TranslocoFallbackStrategy, TRANSLOCO_FALLBACK_STRATEGY } from '@jsverse/transloco';

export class CustomStrategy implements TranslocoFallbackStrategy {
  getNextLangs(failedLang: string): string[] {
    return ['de'];
  }
}

export const customFallbackStrategy = {
  provide: TRANSLOCO_FALLBACK_STRATEGY,
  useClass: CustomStrategy
};
