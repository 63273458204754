import { UserAuthorizationService } from './user-authorization.service';
import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { LogoutComponent } from './logout/logout.component';
import { AuthorizationInterceptor } from './authorization.interceptor';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthGuard } from './guards';
import { HttpResponseErrorInterceptor } from '../shared/interceptors/http-response-error.interceptor';
@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    UserAuthorizationService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseErrorInterceptor, multi: true }
  ],
  declarations: [LogoutComponent, AuthCallbackComponent],
})
export class SecurityModule {

  constructor(@Optional() @SkipSelf() parentModule: SecurityModule) {
    if (parentModule) {
      throw new Error(
        'SecurityModule is already loaded. Import it in the AppModule only');
    }
  }

}
