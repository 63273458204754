import { HttpClient } from '@angular/common/http';
import {
    TRANSLOCO_LOADER,
    Translation,
    TranslocoLoader,
    TRANSLOCO_CONFIG,
    translocoConfig,
    TranslocoModule,
    provideTransloco,
} from '@jsverse/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { customFallbackStrategy } from './fallback-strategy';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) { }

    // getTranslation(lang: string) {
    //   return import(`../../assets/i18n/${lang}.json`).then(res => res.default);
    // }

    getTranslation(lang: string): Observable<Translation> {
        return this.http.get<Translation>(`./assets/i18n/${lang}.json`);
    }

}

@NgModule({
    exports: [TranslocoModule],
    providers: [
        provideTransloco({
            config: {
                availableLangs: ['de'],
                defaultLang: 'de',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: environment.production,
            },
            loader: TranslocoHttpLoader
        }),
        customFallbackStrategy,


        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    ],
})
export class TranslocoRootModule { }
